.card-container {
  display: flex;
  flex-grow: 1;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 14px;
  background:  rgba(74, 78, 82, 0.80);
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.30);
}
