.card-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    align-self: stretch;


    &.unavailable {
        opacity: 0.4;
    }

    &--vip {
        margin-top: 20px;
    }


    .package-title {
        color: #D1DFEF;
        background: none;
        --webkit-text-fill-color: unset;
        --webkit-background-clip: text;
    }

    .card-description {
        display: flex;

        p {
            color: #F4F3F1;
            font-family: "Spline Sans";
            font-size: 16px;
            font-style: normal;
            margin: 0;
            font-weight: 400;
            line-height: 24px; /* 150% */
        }
    }

    .card-action {
        display: flex;
        flex-direction: column;
    }

    &.card-slide {
        width: 100%;
        .package-image {
            img {
                width: 100%;
            }
        }
    }

    &.card-slide--vip {

        .package-image {
            margin-left: -20px;
            margin-right: -20px;
            margin-top: -20px;

            img {
                width: 100%;
            }
        }
    }
}
