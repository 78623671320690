.input-component {
  display: flex;
  align-items: center;
  align-self: stretch;
  position: relative;
  background-color: rgba(18, 18, 25, 0.60);
  border-top: 1px solid rgba(209, 222, 239, 0.30);
  border-right: 1px solid rgba(209, 222, 239, 0.30);
  border-left: 1px solid rgba(209, 222, 239, 0.30);
  padding: 16px;

  &:first-child {
    border-radius: 12px 12px 0 0;
  }

  &:last-child {
    border-bottom: 1px solid rgba(209, 222, 239, 0.30);
    border-radius: 0 0 12px 12px;

  }

  &.valid {
    border-color: #7AC5A1;

    &:after {
      content: "✔";
      position: absolute;
      right: 16px;
      top: 16px;
      color: #80bda0;
    }
  }

  &.error {
    border-color: #FA6589;

    input {
      color: #FA6589
    }
  }

  &.error {
    input {
      //border-bottom-color: #FA6E65;
    }
  }

  label {
    left: 16px;
    position: absolute;
    z-index: 0;
    top: 16px;
    font-size: 16px;
    transition: all 0.2s ease-out;
    color: #F4F3F1;
    font-family: "Spline Sans", serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5em;
  }

  &.valid {
    label {
      color: #F4F3F1;
      font-size: 14px;
      font-weight: 400;
      top: 5px;
      left: 16px;
    }

    input {
      bottom: -8px;
      left: -2px;
    }
  }

  input {
    font-size: 16px;
    outline: none;
    border: 0;
    width: 100%;
    max-width: 100%;
    transition: all 0.2s ease-in;
    z-index: 1;
    position: relative;
    border-radius: 0;
    color: #F4F3F1;
    background: transparent;
    font-family: "Spline Sans", serif;
    font-style: normal;
    font-weight: 700;
    line-height: var(--wm-font-size-big); /* 150% */
    align-items: center;

    //border-top: 1px solid var(--wm-color-success);
    //border-right: 1px solid var(--wm-color-success);
    //border-left: 1px solid var(--wm-color-success);

    &:valid,
    &:focus {
      bottom: -8px;
      left: -2px;
    }

    &:valid ~ label,
    &:focus ~ label {
      color: #F4F3F1;
      font-size: 14px;
      font-weight: 400;
      top: 5px;
      left: 16px;
    }

    // &:valid,
    // &:focus {
    //     border-color: #f4f1f1;
    // }
  }
}
