.package-info {
    display: flex;
    flex-direction: column;
    padding-top: 4%;

    padding-bottom: 10%;

    select {
        width: 100%;
        height: 42px;
        margin: 6px 0 24px;
        padding: 0 2px;
        // border-radius: 21px;
        // box-shadow: -4px -4px 8px 0 rgba(22, 21, 22, 0.4), 6px 6px 12px 0 rgba(249, 243, 238, 0.1),
        //     inset 0 4px 12px 0 rgba(0, 0, 0, 0.5);
        border-style: solid;
        border-width: 1px;
        background: transparent;
        font-size: 16px;
        color: #f4f1f1;
        border: 0;
        outline: none;

        &:focus {
            outline: none;
        }
    }

    .package-name {
        /* Headings/H1 */
        font-family: "Bebas Neue", serif;
        font-size: 60px;
        font-style: normal;
        font-weight: 400;
        line-height: 95%;
        text-transform: uppercase;

        background: linear-gradient(97deg, #FFB80C 4%, #E8ACB0 71.74%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &.vip {
            font-style: normal;
            font-size: 60px;
            font-style: normal;
            font-weight: 400;
            line-height: 95%;
            text-transform: uppercase;

            background: linear-gradient(97deg, #FFB80C 4%, #E8ACB0 71.74%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .under-text {
        margin-top: -20px;
    }

    .small-impact {
        font-size: 30px;
        margin-top: -32px;
    }

    .package-description {
        margin: 16px 0;
    }

    .instructions {
        color: #F4F3F1;
        font-family: "Spline Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        margin: 20px 0;
    }

    .select-pickup-location {
        select {
            font-size: 18px;
            width: 100%;
        }
    }

    .button-container {
        width: 100%;
        margin: 16px 0;
        justify-items: center;
        align-items: center;
        display: flex;
        flex-direction: column;

        .buy-button {

            &.disabled {
                opacity: 0.3;
            }
        }
    }

    .address {
        margin-top: 42px;
        display: flex;
        flex-direction: column;
        fit-content: 1;
    }

    .select {
        font-size: 16px;
        outline: none;
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid #f4f1f1;
        width: 100%;
        transition: all 0.2s ease-in;
        z-index: 1;
        position: relative;
        border-radius: 0;
        padding-left: 0;
        font-weight: 500;
        padding: 0;
        line-height: 12px;
        margin: 0;
        text-indent: -1px;
        box-sizing: border-box;
        color: #f4f1f1;
        height: 24px;
        &:valid ~ label,
        &:focus ~ label {
            color: #f4f1f1;
            font-size: 0.8rem;
            top: -16px;
        }

        &.valid {
            border-bottom-color: #80bda0;
        }

        &.error {
            border-bottom-color: #fa6e65;
        }
    }
}

.modal-title {
    height: 4vh;
    line-height: 4vh;

    font-size: 20px;
    font-weight: bold;
}

.modal-layout {
    display: flex;
    flex-direction: column;
    height: calc(76vh - 40px);
}

.modal-content {
    flex: 1 1 auto;
    overflow-y: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 5px;

    .county {
        display: flex;
        padding: 14px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border-radius: 22px;
        background: rgba(216, 209, 193, 0.2);
        margin-bottom: 8px;

        &.selected {
            border: 1px solid var(--wm-gold, #ffb80c);
            background: rgba(30, 30, 30, 0.4);
        }
    }
}

.modal-button {
    flex: 0;
}
