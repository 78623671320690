body {
    margin: 0;
    font-family: 'Spline Sans', 'Helvetica Neue', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    padding: 0;
    // width: 100%;
    // max-width: 100%;
    // height: 100%;
    // max-height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-user-drag: none;
    -ms-content-zooming: none;
    touch-action: manipulation;
    word-wrap: break-word;
    text-size-adjust: none;
    user-select: none;

    font-size: 14px;

    color: #F4F1F1;
    background-color: #35282a;
    background-image: url('assets/activate-bg.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    min-height: 100dvh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
    border: 0;
    font-family: inherit;
    font-style: inherit;
    font-variant: inherit;
    line-height: 1;
    text-transform: none;
    cursor: pointer;
    -webkit-appearance: button;
}

a,
a div,
a span,
a ion-icon,
a ion-label,
button,
button div,
button span,
button ion-icon,
button ion-label,
[tappable],
[tappable] div,
[tappable] span,
[tappable] ion-icon,
[tappable] ion-label,
input,
textarea {
    -ms-touch-action: manipulation;
    touch-action: manipulation;

    &:focus,
    &:active {
        outline: 0;
        border: none;
        -moz-outline-style: none;
        outline-style: none;
    }
}

@font-face {
    font-family: 'Spline Sans';
    src: url('assets/fonts/splinesans/SplineSans-Bold.eot');
    src: url('assets/fonts/splinesans/SplineSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/splinesans/SplineSans-Bold.woff2') format('woff2'),
        url('assets/fonts/splinesans/SplineSans-Bold.woff') format('woff'),
        url('assets/fonts/splinesans/SplineSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Spline Sans';
    src: url('assets/fonts/splinesans/SplineSans-Light.eot');
    src: url('assets/fonts/splinesans/SplineSans-Light.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/splinesans/SplineSans-Light.woff2') format('woff2'),
        url('assets/fonts/splinesans/SplineSans-Light.woff') format('woff'),
        url('assets/fonts/splinesans/SplineSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Spline Sans';
    src: url('assets/fonts/splinesans/SplineSans-Regular.eot');
    src: url('assets/fonts/splinesans/SplineSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/splinesans/SplineSans-Regular.woff2') format('woff2'),
        url('assets/fonts/splinesans/SplineSans-Regular.woff') format('woff'),
        url('assets/fonts/splinesans/SplineSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.periodOver {
    display: flex;
    justify-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;

    div {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        padding: 32px;
    }
}

button {
    padding: 14px;
    font-weight: 500;
    overflow: hidden;
    width: 100%;

    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    border-radius: 24px;
    background: #ffb80c;

    color: #272528;
    text-align: center;
    font-family: 'Spline Sans', 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    line-height: 16px; /* 100% */

    &.glow {
        border-radius: 24px;
        background: #FFB80C;
        color: #272622;
        font-weight: 700;
        box-shadow: 0 4px 30px 0 rgba(255, 184, 12, 0.50);
    }
}

.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
    //background: #272622
}

.wm-title {
    /* Headings/H1 */
    font-family: 'Spline Sans';
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 133.333% */
    background: var(--WM-Display-Text-Gradient, linear-gradient(97deg, #F3E7CA 4%, #BCD5FA 71.74%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.input-box {
    position: relative;
    margin-top: 16px;
    display: flex;

    label {
        font-family: Spline Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        left: 0;
        position: absolute;
        top: 5px;
        z-index: 0;
    }

    input {
        // font-family: 'HKCompression-HeavyCondensed', 'Roboto', sans-serif;
        //
        flex: 1;
        color: #f4f1f1;
        overflow: hidden;
        border: 0;
        border-bottom: 1px solid #f4f1f1;
        background-color: transparent;
        z-index: 1;

        padding: 4px 0;

        font-family: Spline Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;

        // &:valid,
        // &:focus {
        //     border-color: #009a00;
        //     border-width: 5px;
        // }

        &:valid ~ label,
        &:focus ~ label {
            font-size: 0.8rem;
            top: -18px;
            transition: all 0.3s;
        }
    }
}


.input-box-v2 {
    position: relative;
    margin-top: 16px;
    display: flex;
    color: #F4F3F1;

    label {
        font-family: 'Spline Sans';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        left: 16px;
        position: absolute;
        top: 16px;
        color: #F4F3F1;
        z-index: 2;
    }

    input {
        flex: 1;
        overflow: hidden;
        border: 1px solid rgba(209, 222, 239, 0.30);
        border-radius: 12px;
        background: rgba(18, 18, 25, 0.60);
        padding: 16px;
        z-index: 2;
        font-weight: 400;
        font-family: 'Spline Sans';
        color: #F4F3F1;
        font-size: 16px;
        font-style: normal;
        line-height: 24px;

        // &:valid,
        // &:focus {
        //     border-color: #009a00;
        //     border-width: 5px;
        // }

        &:valid ~ label,
        &:focus ~ label {
            display: none;
            transition: all 0.3s;
        }
    }
}


.button-container {
    &.button-container__submit {
      button {
          border-radius: 24px;
          border: 1px solid #FFB80C;
          background: rgba(18, 18, 25, 0.60);
          color: #FFB80C;
          /* Interactive */
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 1em;
          display: flex;
          flex-direction: row;
          gap: 8px;
          justify-content: center;

          svg {
                margin-top: 1px;
          }
      }
    }
}
.user-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    padding: 10px;

    .username {
        flex: 2;
        margin-left: auto;
        text-align: right;
    }
}

.order-result {
    &.return-fail {
        //background-image: url('/return-bg-fail.png');
    }
    .result-info {
        margin: 24px auto 32px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-between;

        .card-container {
            flex-grow: 0;

            button {
                margin-top: 20px;
            }
        }
        .title {
            display: flex;

            font-family: Spline Sans;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: 50px; /* 125% */
            background: linear-gradient(144deg, #f6ba8f 0%, #a0afd7 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .fail {
        .result-info {
            margin: 52% 24px 0 24px;
        }
        .button-container {
            margin-top: 32px;
        }
    }

    .image-container {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
        }
    }

    .description {
        padding: 16px 0;
    }

    .small {
        padding-top: 16px;
        font-size: 14px;
    }

    .payment-status {
        font-size: 20px;
    }

    .button-container {
        justify-items: center;
        align-items: center;
        display: flex;
        flex-direction: column;

        // button {
        //     border-radius: 64px;
        //     padding: 0 2.6rem;
        //     font-family: 'HKCompression-HeavyCondensed', 'Roboto', sans-serif;
        //     font-size: 25px;
        //     height: 2em;
        //     color: #303030;
        //     background-color: #fac400;
        //     font-weight: 500;
        //     overflow: hidden;
        //     width: 100%;
        // }
    }

    .loading {
        text-align: center;
        display: flex;
        justify-content: center;
        height: 100vh;
        align-items: center;
    }

    .full {
        flex: 1;
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;

    }
}
