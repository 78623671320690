.home-page {
    background: #272622;
    background-size: 100% auto;
    min-height: 100dvh;
    padding: 16px;
    display: flex;
    flex-direction: column;


    .top-bar {
        background-color: rgba(18, 18, 25, 0.6);
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: -16px -16px 16px -16px;

        .back-arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px 0 6px 6px;
        }
        .title {
            height: 100%;
            font-family: "Spline Sans", serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5em;
        }
    }
    .packages-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: -20px;
        gap: 16px;
    }
    .packages-wrapper {
        margin: 0 auto 40px;
        align-items: stretch;

        .donation-info {
            font-family: 'HKCompression-HeavyCondensed', 'Roboto', sans-serif;
            font-size: 24px;
            text-align: center;
            padding-bottom: 16px;
        }
    }

    .activate-container {
        display: flex;
        gap: 20px;
        margin-bottom: 16px;
        > .wm-title {
            width: 70%
        }

        > .package-select-text {
            color: #F4F3F1;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 1.5em; /* 150% */
        }
    }


    // .user-wrapper {
    //     display: flex;
    //     flex-direction: row;
    //     margin-left: auto;
    //     justify-content: space-between;
    //     font-size: 14px;
    //     font-weight: bold;
    //     padding: 10px;

    //     .username {
    //         flex: 2;
    //         margin-left: auto;
    //         text-align: right;
    //     }
    // }

    .back-arrow {
        font-size: 36px;
        padding: 6px;
        cursor: pointer;
    }

    .package-select-text {
        /* Paragraph */
        font-family: Spline Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px; /* 150% */
    }

    .activation-code {
        /* Strong */
        width: 100%;
        font-family: Spline Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        display: flex;
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;


        .activation-code__title {
            color: #F4F3F1;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 1.5em
        }

    }

    .loading-indicator {
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: #8989893d;
        z-index: 60;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lds-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }
    .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

.modal-container {
  display: flex;
}

.modal-content {
    p {
        color: #F4F3F1;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5em;
    }

   .modal-buttons {
       position: absolute;
       bottom: 20px;
       left: 10px;
       right: 10px;
       button {
         margin-top: 12px !important;
         &.secondary {
           border-radius: 24px;
           border: 1px solid #FFB80C;
           background: rgba(18, 18, 25, 0.60);
           color: #FFB80C;
         }
      }
   }
}
