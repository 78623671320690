.splash-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    // background-image: url('/splash.png');
    // background-position: top;
    // background-repeat: no-repeat;
    // background-size: auto 100%;

    img {
        height: 100vh;
        width: 100%;
        object-fit: cover;
    };

    .text {
        position: absolute;
        text-align: center;
        justify-content: center;
        justify-items: center;
        justify-self: center;
        margin-top: 40%;
        display: flex;
        flex: 1;
        flex-direction: column;
        color: white;

        .season {
            flex: 1;
            font-size: 54px;
            font-family: 'HKCompression-HeavyCondensed', 'Roboto', sans-serif;
        }

        .date {
            flex: 1;
            font-family: 'HKGroteskPro', 'Roboto', sans-serif;
            font-size: 18px;
            font-weight: bold;
        }
    }
}