.logged-out {
    .download-app {
        display: flex;
        font-family: 'HKCompression-HeavyCondensed', 'Roboto', sans-serif;
        font-size: 26px;
        align-items: center;
        text-align: center;
        height: 100vh;
        justify-content: center;
        padding: 16px;
    }
}
