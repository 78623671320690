.return {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    background-color: #272622;
    // background-image: url('/return-bg.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% auto;

    min-height: 100dvh;
    padding: 0 12px;

    .descLong {
        font-family: Spline Sans, 'Roboto', sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
    }

    .loading {
        text-align: center;
        display: flex;
        justify-content: center;
        height: 100vh;
        align-items: center;
    }
}
