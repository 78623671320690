.checkbox-component {
    display: flex;
    flex-direction: row;
    margin: 4px 0 10px;

    .check-icon {
        width: 24px;
        border: 1px solid #F4F1F166;
        height: 24px;
        border-radius: 4px;
        margin-right: 8px;
        flex-shrink: 0;
    }
    .label {
        align-self: center;
    }
}
